import { RemixBrowser } from '@remix-run/react';
import { useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';
import { hydrateRoot } from 'react-dom/client';

import type { Window } from '~/components/root/.types/globalTypes';

const { env, user, account } = window as Window;

if (env?.SENTRY_APP_SSR_API_KEY) {
  Sentry.init({
    dsn: env.SENTRY_APP_SSR_API_KEY,
    environment: env.GC_ENV,
    debug: env.GC_ENV !== 'production',
    initialScope: {
      user: { id: String(user?.id) },
      contexts: {
        account: {
          id: String(account?.id),
          slug: account?.attributes?.slug,
          features: account?.attributes?.features,
        },
      },
    },
    tracesSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
    tracePropagationTargets: ['localhost', new RegExp(`^https:\\/\\/${env.API_URL}/`), 'http://api/'],
  });
}

hydrateRoot(document, <RemixBrowser />);
